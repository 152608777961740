import {ApiResponse, ApprovaCartSedData, IVoucherResultResponse} from 'pages/Wizard/types';
import core from './core';
import queryString from 'query-string';
import {AxiosResponse} from 'axios';

export const getBillingInformation = (uuid: any) =>
  core.get(`/api/2/account/${uuid}/billing-info`).then(({data}) => data?.results);

export const saveBillingInformation = (sendData: any, uuid: any) =>
  core.post(`/api/2/account/${uuid}/billing-info`, queryString.stringify(sendData));

export const getPayoneerAccountData = (uuid: string) =>
  core.get(`/api/2/account/${uuid}/payee/status`).then(({data}) => data);

export const sendNewPayoneerStatus = (userId: string | number, fd: FormData) =>
  core.post(`/api/v2/admin/payoneer/translator/${userId}/change-status `, fd).then(({data}) => data);

export const removePayoneerAccountData = (uuid: string, csrftoken: string) =>
  core.delete(`/api/2/account/${uuid}/payee/unlink?CSRFToken=${csrftoken}`).then(({data}) => data);

export const updateExpedite = (sendData: any) => core.post('/api/2/expedite/update', queryString.stringify(sendData));

export const onGetVendorData = (pqid: any) =>
  core.get(`/api/2/cart/vendorurl?pqid=${pqid}`).then((data) => data?.data?.results);

export const executeRecurringPayment = (pqid: any) =>
  core.post('/ajax/cart/executeRecurringPayment', queryString.stringify({tid: pqid}));

export const executeWiretransferPayment = (sendData: any) =>
  core.post('/ajax/cart/wiretransferapply', queryString.stringify(sendData));

export const submitCoupon = (sendData: any): Promise<AxiosResponse<ApiResponse<IVoucherResultResponse>>> =>
  core.post('/ajax/cart/submitCoupon', queryString.stringify(sendData));

export const removeCoupon = (sendData: any) => core.post('/ajax/cart/removeCoupon', queryString.stringify(sendData));

export const getUpsalesInfo = () => core.get('/registry/get?data=Upsales').then((data) => data?.data?.results);

export const onApproveCart = (sendData: ApprovaCartSedData, couponIds: string[] = []) => {
  const formData = new FormData();
  Object.keys(sendData).forEach((key) => {
    formData.append(key, (sendData as any)[key]);
  });

  couponIds.forEach((couponId, index) => {
    formData.append(`couponIds[${index}]`, couponId);
  });

  return core.post('/ajax/cart/translateNow', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const onUpdatePaymentInfo = (ck: string | null, skipPQCreation?: number) => {
  const query = `${skipPQCreation ? `&skipPriceQuoteCreation=${skipPQCreation}` : ''}`;
  return core.get(`registry/get?data=PaymentInformation&ck=${ck}${query}`).then((data) => data?.data?.results);
};

export const preloadUserData = () => core.post('/api/2/internal/user/un-attach-coupons').then((data) => data);

export const changeSendEmail = (sendData: any) => core.post('/api/2/cart/update', queryString.stringify(sendData));

export const onChangeNeedProofReading = (ck: string | null, status: boolean) =>
  core.get(`/ajax/cart/needProofReading?ck=${ck}&status=${status}`).then((data) => data?.data?.results);

export const onChangeNeedCertificate = (sendData: any) =>
  core.post('/api/2/certificate/update', queryString.stringify(sendData));

export const getCurrencyList = (baseUrl: string, bearerToken: string) =>
  core
    .get(`${baseUrl}/currency`, {
      headers: {
        authorization: `Bearer ${bearerToken}`,
      },
    })
    .then(({data: {elements}}) => elements);
