import core from './core';
import {ApiResponse} from 'pages/Wizard/types';
import {FormValueProps} from 'pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';
import {
  IDefaultMyProjectsResponse,
  ILlMyProjectsResponse,
  IReviewMyProjectsResponse,
  IReviewedByQaLLMyProjectsResponse,
} from 'pages_refactor/TranslatorMyProjects/TranslatorMyProjectsUtils';

export const getMyProjectsDefault = (params: FormValueProps): Promise<ApiResponse<IDefaultMyProjectsResponse>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/translator-projects?${queryString}`).then(({data}) => data);
};

export const getMyProjectsReviews = (params: FormValueProps): Promise<ApiResponse<IReviewMyProjectsResponse>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/project/cqr/reviews?${queryString}`).then(({data}) => data);
};

export const getMyProjectsLl = (params: FormValueProps): Promise<ApiResponse<ILlMyProjectsResponse>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/project/ll/reviews?${queryString}`).then(({data}) => data);
};

export const getMyProjectsReviewedByQaLl = (
  params: FormValueProps
): Promise<ApiResponse<IReviewedByQaLLMyProjectsResponse>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/translator-qa-ll-projects?${queryString}`).then(({data}) => data);
};
