import core from './core';

export function getActionTypes() {
  return core.get('api/2/credit_action/types');
}

export function getTags() {
  return core.get('api/2/account/tags');
}

export function getStatementData(
  dateFrom: string,
  dateTo: string,
  sourceLang: string,
  targetLang: string,
  actionType: string,
  tag: string,
  page: number,
  limit: number,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  let query = `date_from=${dateFrom}&date_to=${dateTo}&source_lang=${sourceLang}&target_lang=${targetLang}&action_type=${actionType}`;
  query += `&tag_id=${tag}&cpage=${++page}&limit=${limit}&sortBy=${orderBy}&sortDirection=${
    order === 'asc' ? order : 'dec'
  }`;

  return core.get(`api/2/account/statement/get?${query}`);
}

export function sendStatementReport({fd}: any): Promise<any> {
  return core.post('fileService/sendCustomerReportAsFile', fd);
}
