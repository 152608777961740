import React, {memo, Suspense} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@mui/material/CssBaseline';
import Loader from './components/SuspenseLoading';
import NotificationsContainer from './components/Notifications/NotificationsContainer';
import Routes from './Routes';
import { UserProvider } from './context/UserData';
import ScrollToTop from './utils/ScrollToTop';
import WebsocketHandler from './components/GeneralComponents/WebsocketHandler';
import ErrorBoundaryWithLogging from './components/ErrorBoundary/ErrorBoundary';
import theme from './theme/theme';
import { TrackingProvider } from 'context/Tracking/TrackingProvider';
import './assets/base.scss';
import {OverrideStyles} from './theme/overrideStyles';

const queryClient = new QueryClient();

const AppProviders = ({ children }: any) => (
  <ErrorBoundaryWithLogging>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserProvider>
          <TrackingProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ScrollToTop>
                {children}
              </ScrollToTop>
            </ThemeProvider>
          </TrackingProvider>
        </UserProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </ErrorBoundaryWithLogging>
);

const App = memo(() => (
  <AppProviders>
    <Suspense fallback={<Loader />}>
      <OverrideStyles />
      <NotificationsContainer hideProgressBar pauseOnHover autoClose={5000} />
      <Routes />
      <WebsocketHandler />
    </Suspense>
  </AppProviders>
));

export default App;
