import core from './core';
import {ApiResponse} from 'pages/Wizard/types';
import {FormValueProps} from 'pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';
import {
  IBonusProject,
  IClassicProjectResponse,
  ILLProject,
  IQAProject,
} from 'pages_refactor/AvailableProjects/AvailableProjectsUtils';
import {IProjectCountWithJobsResponse, IProjectsCountResponse} from 'store/pages/availableProjectsStore';

export const getClassicAvailableProjects = (params: FormValueProps): Promise<ApiResponse<IClassicProjectResponse>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/job/open?${queryString}`).then(({data}) => data);
};

export const getBonusAvailableProjects = (params: FormValueProps): Promise<ApiResponse<IBonusProject[]>> => {
  // interface for response it's just guess
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/bonus?${queryString}`).then(({data}) => data);
};

export const getQaAvailableProjects = (params: FormValueProps): Promise<ApiResponse<IQAProject[]>> => {
  // interface for response it's just guess
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/project/qa?${queryString}`).then(({data}) => data);
};

export const getQaAvailableProjectsCount = (params: FormValueProps): Promise<ApiResponse<IProjectsCountResponse>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/project/qa/count?${queryString}`).then(({data}) => data);
};

export const getRatingAvailableProjectsOrCount = (
  params: FormValueProps
): Promise<ApiResponse<IProjectCountWithJobsResponse>> => {
  // interface for response it's just guess
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/account/rating-projects?${queryString}`).then(({data}) => data);
};

export const getLLAvailableProjects = (params: FormValueProps): Promise<ApiResponse<ILLProject[]>> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/ll/inhouse/jobs?${queryString}`).then(({data}) => data);
};

export const getLLAvailableProjectsCount = (params: FormValueProps): Promise<ApiResponse<IProjectsCountResponse>> => {
  const queryString = new URLSearchParams({...params, count: 'true'});

  return core.get(`/api/2/ll/inhouse/jobs?${queryString}`).then(({data}) => data);
};
