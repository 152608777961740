import core from './core';
import {GetProbationResponse, ProbationManagementField} from '../store/pages/probationManagementStore';

export function editProbation(field: ProbationManagementField, token: string) {
  const formData = Object.keys(field).reduce((acc, key) => {
    const value = field[key as keyof ProbationManagementField];

    if (Array.isArray(value)) {
      value.forEach((item) => {
        acc.append(`${key}[]`, item);
      });
    } else {
      value !== null && acc.append(key, value?.toString());
    }

    return acc;
  }, new FormData());

  return core({
    method: 'POST',
    url: 'api/2/admin/probation/params',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}`},
  });
}

export function getProbation(token: string): Promise<GetProbationResponse> {
  return core
    .get('api/2/admin/probation/params', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({data}) => data?.results);
}
