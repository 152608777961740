import core from './core';
import * as wizard from './wizard';
import * as customerStatement from './customerStatement';
import * as messageBox from './messageBox';
import * as admin from './admin';
import * as invoices from './invoices';
import * as alerts from './alerts';
import * as partner from './partner';
import * as profile from './profile';
import * as payment from './payment';
import * as shoppingCart from '../pages/ShoppingCart/shoppingCartApi';
import * as voices from './voices';
import * as backoffice from './backoffice';
import * as blendTalk from './blendTalk';
import * as moderation from './moderation';
import * as blendTalkForAdmin from './blendTalkForAdmin';
import * as probationManagement from './probationManagement';
import * as convertStoreAPI from './convertStoreAPI';
import * as sharedTM from './sharedTM';

const api = {
  core,
  wizard,
  customerStatement,
  messageBox,
  admin,
  alerts,
  partner,
  invoices,
  profile,
  payment,
  shoppingCart,
  voices,
  backoffice,
  blendTalk,
  moderation,
  blendTalkForAdmin,
  probationManagement,
  convertStoreAPI,
  sharedTM,
};

export default api;
