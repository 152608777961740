import core from './core';
import {handleAddAuthToken} from 'pages/BlendTalk/BlendTalkUtils';
import {
  VocabularyLanguage,
  VocabularyLanguageRequest,
  VocabularyWord,
  VocabularyWordSearchResult,
} from 'pages/Backoffice/components/Vocabulary/VocabularyUtils';
import {
  ModerationFieldForSearch,
  ModerationHistoryStatus,
  ModerationListItem,
  PmsClientsModerationResponse,
} from '../store/pages/moderationStore';
import {ApiResponse} from 'pages/Wizard/types';
import dayjs from 'dayjs';

export const getLanguageList = (token: string): Promise<VocabularyLanguage[]> =>
  core.get('/moderation/api/languages', handleAddAuthToken(token)).then(({data}) => data);

export const createLanguage = (title: string, token: string): Promise<VocabularyLanguage> =>
  core.post('/moderation/api/languages', {title}, handleAddAuthToken(token)).then(({data}) => data);

export const editLanguage = (id: string, title: string, token: string): Promise<VocabularyLanguage> =>
  core
    .patch(
      `/moderation/api/languages/${id}`,
      {title},
      {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )
    .then(({data}) => data);

export const searchWord = (
  search: string,
  token: string
): Promise<{
  words: VocabularyWordSearchResult[];
}> => core.get(`/moderation/api/search/word/${search}`, handleAddAuthToken(token)).then(({data}) => data);

export const createWord = (
  title: string,
  language: VocabularyLanguageRequest,
  token: string
): Promise<VocabularyWord> =>
  core.post('/moderation/api/words', {title, language}, handleAddAuthToken(token)).then(({data}) => data);

export interface WordListResponse {
  data: VocabularyWord[];
  paginationItemsPerPage?: number;
  paginationTotalItems?: number;
}

export const getWordList = (id: string, token: string, page: number): Promise<WordListResponse> =>
  core.get(`/moderation/api/words/${id}?page=${page}`, handleAddAuthToken(token)).then(({data, headers}) => {
    const paginationItemsPerPage = +headers['pagination-items-per-page'] || 30;
    const paginationTotalItems = +headers['pagination-total-items'] || 0;

    return {data, paginationItemsPerPage, paginationTotalItems};
  });

export const removeWord = (id: string, token: string) =>
  core.delete(`/moderation/api/words/${id}`, handleAddAuthToken(token)).then(({data}) => data);

export const getPmCustomersList = (
  pmId: number | string,
  token: string
): Promise<ApiResponse<PmsClientsModerationResponse>> => {
  return core.get(`/api/2/admin/account-managers/${pmId}/customers`, handleAddAuthToken(token)).then(({data}) => data);
};

export const getTableModerationList = (
  field: ModerationFieldForSearch,
  token: string
): Promise<ModerationListItem[]> => {
  const queryParams = Object.entries(field)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (key === 'until') {
        return `createdAt[before]=${dayjs(value).format('YYYY-MM-DD')}T23:59:59`;
      }
      if (key === 'from') {
        return `createdAt[after]=${dayjs(value).format('YYYY-MM-DD')}`;
      }
      if (key === 'author.externalId' && Array.isArray(value)) {
        return `${key}[]=${value.join(`&${key}[]=`)}`;
      }
      return `${key}=${value}`;
    })
    .join('&');

  return core
    .get(`/moderation/api/moderations/search?${queryParams}`, handleAddAuthToken(token))
    .then(({data}) => data);
};

export const updateModerationStatus = (
  status: ModerationHistoryStatus,
  moderationId: string,
  token: string
): Promise<ModerationListItem> =>
  core
    .patch(
      `/moderation/api/moderations/${moderationId}`,
      {status},
      {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )
    .then(({data}) => data);
