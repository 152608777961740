import core from './core';
import queryString from 'query-string';

export function getMessages(
  page: number,
  limit: number,
  filter_status: string,
  filter_type: string,
  CSRFToken: string
) {
  const query = `page=${page}&limit=${limit}&filter_status=${filter_status}&filter_type=${filter_type}&CSRFToken=${CSRFToken}`;
  return core.get(`/api/2/account/messages?${query}`);
}

export function readMessage(id: string, CSRFToken: string) {
  return core.post(`api/2/account/messages/${id}/read`, queryString.stringify({CSRFToken}));
}
