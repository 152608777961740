import axios from 'axios';
import core from './core';
import {
  AddedUsers,
  ChatContact,
  Chat,
  ChatMessage,
  CurrentUser,
  ChatAttachment,
  ChatSearch,
  UploadedFile,
  Attachment,
  UnreadNotification,
} from 'store/pages/blendTalkStore';
import {handleAddAuthToken, handleAddPatchAuthToken} from 'pages/BlendTalk/BlendTalkUtils';
import Consts from 'pages/BlendTalk/BlendTalkConsts';

const {API, API_ADMIN} = Consts;

const instance = axios.create({
  baseURL: API,
});

export const updateBaseUrl = (isAdmin: boolean) => {
  instance.defaults.baseURL = isAdmin ? API_ADMIN : API;
};

export const addNewMember = (chatId: string, userId: string, token: string): Promise<ChatContact> =>
  core
    .post(
      `${API}/chat-members`,
      {
        chat: {id: chatId},
        user: {id: userId},
      },
      handleAddAuthToken(token)
    )
    .then(({data}) => data);

export const getAllMyContacts = (token: string, customerID: string | null): Promise<ChatContact[]> => {
  const query = customerID ? `/chat/api/admin/user-contacts/${customerID}` : '/chat/api/my-contacts';
  return core.get(query, handleAddAuthToken(token)).then(({data}) => data);
};

export type ChatList = Chat[];

export const getAllChats = (token: string, adminUserId?: string | null): Promise<ChatList> => {
  const query = adminUserId ? `/${adminUserId}` : '';
  return instance.get(`/chats${query}`, handleAddAuthToken(token)).then(({data}) => data);
};

export const createChat = (title: string, members: AddedUsers, token: string, customerId?: string): Promise<Chat> => {
  const params = customerId ? {title, members, customer: {id: customerId}} : {title, members};
  return instance.post('/chats', params, handleAddAuthToken(token)).then(({data}) => data);
};

export const editChat = (chatId: string, chat: Partial<Chat>, token: string) =>
  instance.patch(`/chats/${chatId}`, chat, handleAddPatchAuthToken(token)).then(({data}) => data);

export const deleteMemberFromChat = (chatId: string, userId: string, token: string) =>
  core.delete(`${API}/chat-members/${chatId}/${userId}`, handleAddAuthToken(token)).then(({data}) => data);

export const fetchChatMembers = (chatId: string, token: string): Promise<ChatContact[]> =>
  core.get(`${API}/chat-members/${chatId}`, handleAddAuthToken(token)).then(({data}) => data);

export const fetchMessages = (chatId: string, page: number, token: string): Promise<ChatMessage[]> =>
  core.get(`${API}/chats/${chatId}/messages?page=${page}`, handleAddAuthToken(token)).then(({data}) => data);

export const createMessage = (
  id: string,
  text: string,
  token: string,
  attachments: Attachment[]
): Promise<ChatMessage> =>
  core
    .post(`${API}/direct-messages`, {text, chat: {id}, attachments}, handleAddAuthToken(token))
    .then(({data}) => data);

export const getUserData = (token: string): Promise<CurrentUser> =>
  core.get(`${API}/current-user`, handleAddAuthToken(token)).then(({data}) => data);

export const readChat = (chatId: string, token: string) =>
  instance.patch(`/chats/${chatId}/seen`, {}, handleAddPatchAuthToken(token)).then(({data}) => data);

export const createReplyMessage = (
  chatId: string,
  messageId: string,
  text: string,
  token: string
): Promise<ChatMessage> =>
  core
    .post(`${API}/reply-messages`, {chat: {id: chatId}, message: {id: messageId}, text}, handleAddAuthToken(token))
    .then(({data}) => data);

export const createMessageReaction = (messageId: string, type: string, token: string): Promise<ChatMessage> =>
  core.post(`${API}/messages/${messageId}/reaction`, {type}, handleAddAuthToken(token)).then(({data}) => data);

export const removeMessage = (messageId: string, token: string): Promise<ChatMessage> =>
  core.delete(`${API_ADMIN}/messages/${messageId}`, handleAddAuthToken(token)).then(({data}) => data);

export const restoreMessage = (messageId: string, token: string): Promise<ChatMessage> =>
  core.patch(`${API_ADMIN}/messages/${messageId}/restore`, {}, handleAddPatchAuthToken(token)).then(({data}) => data);

export const removeAttachment = (messageId: string, attachmentId: string, token: string): Promise<ChatAttachment> =>
  instance
    .delete(`/messages/${messageId}/attachments/${attachmentId}`, handleAddAuthToken(token))
    .then(({data}) => data);

export const getChatListSearch = (search: string, token: string, userId?: string | null): Promise<ChatSearch> => {
  const query = userId ? `/search/${userId}/${search}` : `/search/${search}`;
  return instance.get(query, handleAddAuthToken(token)).then(({data}) => data);
};

export const getMessageListBySearch = (search: string, chatId: string, token: string): Promise<ChatSearch> =>
  core.get(`${API}/chat/${chatId}/search/${search}`, handleAddAuthToken(token)).then(({data}) => data);

export const uploadFile = (file: File, token: string): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append('file', file);

  return core({
    method: 'POST',
    url: '/file-storage/api/files',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}`},
  }).then(({data}) => data);
};

export const deleteUploadedFile = (id: string, token: string) =>
  core.delete(`/file-storage/api/files/${id}`, handleAddAuthToken(token)).then(({data}) => data);

export const getChatAttachments = (chatId: string, token: string): Promise<ChatAttachment[]> =>
  core.get(`${API}/chat-attachments/${chatId}`, handleAddAuthToken(token)).then(({data}) => data);

export const downloadFile = (fileId: string, filename: string, token: string) =>
  fetch(`/file-storage/api/files/${fileId}/download`, handleAddAuthToken(token))
    .then((response) => response.blob())
    .then((blob) => {
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
      URL.revokeObjectURL(url);
    });

export const getChatNotifications = (token: string): Promise<UnreadNotification[]> =>
  core.get(`${API}/notifications?exists[seenAt]=false`, handleAddAuthToken(token)).then(({data}) => data);

export const getPinnedMessages = (chatId: string, token: string): Promise<ChatMessage[]> =>
  core.get(`${API}/chats/${chatId}/pinnedMessages`, handleAddAuthToken(token)).then(({data}) => data);

export const updateMessage = (messageId: string, isPinned: boolean, token: string): Promise<ChatMessage> =>
  core.patch(`${API}/messages/${messageId}`, {isPinned}, handleAddPatchAuthToken(token)).then(({data}) => data);

export const getChatMessageData = (messageId: string, token: string): Promise<ChatMessage & {chat: {id: string}}> =>
  core.get(`${API}/messages/${messageId}`, handleAddAuthToken(token)).then(({data}) => data);
