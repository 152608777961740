import core from './core';
import {
  ChatListItem,
  FormValueProps,
  UserListItem,
} from '../pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';

export const getUserList = (params: FormValueProps): Promise<UserListItem[]> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/2/admin/chat/getUsers?${queryString}`).then(({data}) => data?.results);
};

export const getCustomersList = (params: string, token?: string): Promise<ChatListItem[]> => {
  return core
    .get(`/chat/api/admin/users${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then(({data}) => data);
};
