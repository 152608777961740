import core from './core';
import {FormDataProps} from '../pages/Backoffice/components/CustomerCreation/CustomerCreationUtils';

export const getAclList = () => core.get('api/2/account/acl-list').then(({data}) => data?.results);

export const createUsers = ({CSRFToken, fullName, email, userName, password, partnerId, credits}: FormDataProps) => {
  const formData = new FormData();
  formData.append('CSRFToken', CSRFToken);
  formData.append('fullName', fullName);
  formData.append('email', email);
  formData.append('userName', userName);
  formData.append('password', password);
  formData.append('partnerId', partnerId);
  credits && formData.append('credits', credits);

  return core.post('/api/2/account/customers', formData).then(({data}) => data);
};
