import core from './core';
import {NewCreditActionDto} from 'pages/Backoffice/components/CreditActions/CreditActionsUtils';

export const getInvoices = () => core.get('api/2/account/invoices');

export const getInvoice = (id: number | undefined) => core.get(`api/2/account/invoice/${id}`);

export const getCreditList = (id: number | string) => core.get(`/service/getCreditList/?uid=${id}`);

export const getCurrencyList = () => core.get(`/api/2/currency`);

export const getReasonByType = (type: string, CSRFToken: string) =>
  core.get(`/api/2/credit_action/action/reason?type=${type}&CSRFToken=${CSRFToken}`);

export const addNewCreditAction = (data: NewCreditActionDto) => core.post('/ajax/creditActions/add', data);

export const saveInvoiceData = (creditActionId: string, invoiceId: string) =>
  core.post('api/2/credit_action/buy_credit/invoice', {creditActionId, invoiceId});

export const getUnparkCredits = (userId: string, CSRFToken: string) =>
  core.get(`/api/2/credit_action/parkedCredits?user_id=${userId}&CSRFToken=${CSRFToken}`);
