import core from './core';
import {GroupItem, InfoResponse} from '../components/SharedTM/SharedTMUtils';

export const getSharedTMInfo = (type: string, user_id: string, token: string): Promise<InfoResponse> =>
  core.get(`/api/2/account/cat-tool-map/${type}?user_id=${user_id}&CSRFToken=${token}`).then(({data}) => data?.results);

export const searchByUserId = (type: string, user_id: string, token: string): Promise<InfoResponse> =>
  core.get(`/api/2/account/cat-tool-map/${type}?user_id=${user_id}&CSRFToken=${token}`).then(({data}) => data?.results);

export const getGroupListByType = (type: string, token: string): Promise<GroupItem[]> =>
  core.get(`/api/2/cat-tool-map-groups/${type}?CSRFToken=${token}`).then(({data}) => data?.results);

export const createGroup = (type: string, root_user_id: string, group_name: string, CSRFToken: string) => {
  const formData = new FormData();

  formData.append('root_user_id', root_user_id);
  formData.append('group_name', group_name);

  return core.post(`/api/2/account/cat-tool-map/${type}/group?CSRFToken=${CSRFToken}`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const deleteGroup = (type: string, root_user_id: string, token: string) =>
  core.delete(`/api/2/account/cat-tool-map/${type}/group?root_user_id=${root_user_id}&CSRFToken=${token}`);

export const addChildUser = (type: string, root_user_id: string, user_id: number, CSRFToken: string) => {
  const formData = new FormData();

  formData.append('root_user_id', root_user_id);

  formData.append('user_id', user_id.toString());

  return core.post(`/api/2/account/cat-tool-map/${type}/group/user?CSRFToken=${CSRFToken}`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const deleteChildUser = (type: string, root_user_id: string, user_id: number, token: string) =>
  core.delete(
    `/api/2/account/cat-tool-map/${type}/group/user?root_user_id=${root_user_id}&user_id=${user_id}&CSRFToken=${token}`
  );
