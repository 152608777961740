import {IClientDataResponse} from 'pages_refactor/MyTeam/MyTeamUtils';
import core from './core';

import {FormValueProps} from 'pages/Backoffice/components/BlendTalkForAdmin/BlendTalkForAdminUtils';

export const getTranslatorsMyTeam = (page: number, params: FormValueProps): Promise<IClientDataResponse> => {
  const queryString = new URLSearchParams(params);

  return core.get(`/api/v2/translator/my-team-customers/${page}?${queryString}`).then(({data}) => data);
};

export const getTranslatorsMyTeamAvailability = (): Promise<boolean> => {
  return core.get(`/api/v2/is-my-team-member`).then(({data}) => !!data?.translatorIsMyTeamMember);
};
